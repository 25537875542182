import { Container, Grid, Typography } from '@mui/material';
import './Preview.scss';

export default function Preview(props) {
    return (
        <div 
            className='preview-root'
            style={{
                backgroundColor: `${props.bgColor}`,
            }}
        >
            <Container>
                <Grid container spacing={4} direction='row'>
                    <Grid item xs={12} sm={6} order={props.flip ? ({xs: 1, sm: 2}) : ({xs: 1, sm: 1})}>
                        <Typography variant="h3" className='preview-title'>{props.title}</Typography>
                        <Typography>{props.desc}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} order={props.flip ? ({xs: 2, sm: 1}) : ({xs: 2, sm: 2})}>
                        <img src={props.img} alt={props.title} className='preview-img' />
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}