import { Link } from 'react-router-dom';
import { Container, Grid, Box, Card, CardMedia, CardContent, CardActions, Button, Typography } from '@mui/material';
import Preview from '../../components/Preview/Preview';
import ContactForm from '../../components/ContactForm/ContactForm';
import BSGLogo from '../../imgs/svgs/logos/BSG-logo.svg';
import MedicalIcon from '../../imgs/svgs/medical-icon.svg';
import FoodBeverageIcon from '../../imgs/svgs/foodbeverage-icon.svg';
import CryogenicIcon from '../../imgs/svgs/cryogenic-icon.svg';
import LaboratoryIcon from '../../imgs/svgs/laboratory-icon.svg';
import ProductionIcon from '../../imgs/svgs/production-icon.svg';
import PPIcon from '../../imgs/svgs/pp-icon.svg';
import SupportIcon from '../../imgs/svgs/support-icon.svg';
import CylinderFiller from '../../imgs/cylinderpreview.png';
import FowlerPlantAerial from '../../imgs/fowler-plant-aerial.jpg';
import PurePreview from '../../imgs/preview-pure-media.JPG';
import MixedPreview from '../../imgs/preview-mixed-media.jpg';
import SpecialPreview from '../../imgs/preview-special-media.jpg';
import EquipmentPreview from '../../imgs/preview-equipment-media.JPG';
import './Home.scss';
    
export default function Home() {
    return (
        <div className="home-root">
            <div className="home-hero">
                <img src={BSGLogo} alt="bsg-logo" className="hero-logo" />
            </div>
            <div className="home-services">
                <Container className="container">
                    <Grid container className="services-container">
                        <Grid item xs={12} sm={6} md={3} className="services-item medical">
                            <Box>
                                <img src={MedicalIcon} alt="Medical Icon" className="services-icon" />
                                <Typography variant="h6" className="services-title">Medical Gases</Typography>
                                <Typography>We provide USP Oxygen, Nitogren NF, Nitrous Oxide, onsite filling of Nitrogen dewars and much more!</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} className="services-item food-beverage">
                            <Box>
                                <img src={FoodBeverageIcon} alt="Food Beverage Icon" className="services-icon" />
                                <Typography variant="h6" className="services-title">Food & Beverage Gases</Typography>
                                <Typography>We provide food grade Nitrogen & CO2 for wineries, Liquid Nitrogen for cryogenic freezers, N2 for nut packaging and much more!</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} className="services-item cryogenic">
                            <Box>
                                <img src={CryogenicIcon} alt="Cryogenic Icon" className="services-icon" />
                                <Typography variant="h6" className="services-title">Bulk Cryogenic Gases</Typography>
                                <Typography>We provide high purity bulk and microbulk cryogenic gases including Liquid Nitrogen, food grade bulk Liquid Nitrogen, food grade bulk Liquid CO2, bulk storage tanks on lease, and much more!</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} className="services-item laboratory">
                            <Box>
                                <img src={LaboratoryIcon} alt="Laboratory Icon" className="services-icon" />
                                <Typography variant="h6" className="services-title">Laboratory Gases</Typography>
                                <Typography>We provide ultra high purity gases, Nitrogen, Helium, Argon, Oxygen, Anaerobic and Aerobic Gas Mixtures, PFT - lung diffusion gas mixtures, CO2 dry ice, and much more!</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className="home-why">
                <Container>
                    <div className="why-hero-wrapper">
                        <Typography variant="h3" className="why-title">Why Us?</Typography>
                        <Typography> Our Barnes Specialty Gas Division focuses primarily on bringing to you High Purity, Medical, Food Grade, Specialty Mixed Gases, and Specialty Gas Equipment with on-site service and technical support.</Typography>
                    </div>
                </Container>
                <Container className="why-container">
                    <Grid container>
                        <Grid item xs={12} md={6} className="why-item-wrapper">
                            <div className="why-item">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={2} className="why-item-image">
                                        <img src={ProductionIcon} alt="Production Icon" className="why-item-vector" />
                                    </Grid>
                                    <Grid item xs={12} md={10}>
                                        <Typography variant="h4">State of the Art Production</Typography>
                                        <Typography> Barnes Specialty Gases uses the latest fill system and analytical instrumentation in our facilities to manufacture the highest purity gases and critical gas mixtures. Efforts are on to make it the first state-of-the-art facility in the entire valley region. To further ensure quality, Barnes Specialty Gases promotes and operates under a strong national brand, Purity Plus.</Typography>
                                    </Grid>                                    
                                </Grid>
                            </div>
                            <div className="why-item">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={2} className="why-item-image">
                                        <img src={PPIcon} alt="Purity Plus Icon" className="why-item-vector" />
                                    </Grid>
                                    <Grid item xs={12} md={10}>
                                        <Typography variant="h4">Purity Plus Quality Assurance</Typography>
                                        <Typography>Purity Plus is a special, external, third party program that inspects, assesses and controls manufacturing procedures and systems. It has mandatory quality audits for the entire system to assure that the quality meets national standards and practices.</Typography>
                                    </Grid>                                    
                                </Grid>
                            </div>
                            <div className="why-item">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={2} className="why-item-image">
                                        <img src={SupportIcon} alt="Support Icon" className="why-item-vector" />
                                    </Grid>
                                    <Grid item xs={12} md={10}>
                                        <Typography variant="h4">Responsive Customer Service</Typography>
                                        <Typography> Since 1949 we have served the marketplace by providing our customers with high quality products and services at competitive prices. Barnes Specialty Gases evolved out of our constant endeavor to give the best service to our widespread customer base throughout the valley. We believe that our biggest asset is our manpower who have been with us for many years and are the source of our strength. From our sales force to our production facilities, our knowledgeable and experienced workforce is dedicated to fulfilling your specialty gas requirements.</Typography>
                                    </Grid>                                    
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} className="why-image-wrapper">
                            <img src={CylinderFiller} alt="cylinder filler" className="why-cylinder-filler" />
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <Preview 
                title="Who We Are"
                desc="Barnes Specialty Gases is a division of Fresno Oxygen & Welding Suppliers, Inc. Barnes Specialty Gases operates from within our Barnes Welding Supply retail locations. We have 12 stores doing business as Barnes Welding Supply. All these stores are strategically located in major industrial hubs along Highway 99 from Bakersfield to Sacramento. We also have 1 location in Salinas to service the central coast. Supporting our stores and our customers we operate four industrial Gas Fill Plants, one Specialty Gas Lab and a Central Distribution Warehouse."
                img={FowlerPlantAerial}
                bgColor="#71a895"
                flip
            />
            <div className="home-preview">
                <Container>
                    <Typography variant="h3" className="preview-title">Our Specialties</Typography>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} md={3}>
                            <Card square className="preview-wrap">
                                <CardMedia
                                    className="preview-media"
                                    image={PurePreview}
                                />
                                <CardContent>
                                    <Typography variant="h6" className="preview-subtitle">Pure Gases</Typography>
                                    <Typography>We offer pure gases in high pressure cylinders and liquid Dewar, and provide different grades suitable for various applications.</Typography>
                                </CardContent>
                                <CardActions>
                                    <Button component={Link} to="/pure-gases" variant='contained' className='preview-button'>Learn More</Button>
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Card square className="preview-wrap">
                                <CardMedia
                                    className="preview-media"
                                    image={MixedPreview}
                                />
                                <CardContent>
                                    <Typography variant="h6" className="preview-subtitle">Mixed Gases</Typography>
                                    <Typography>Mixtures are manufactured using high precision gravimetric scales combined with the state of the art blending system using Automated Pre & Post fill cylinder treatment.</Typography>
                                </CardContent>
                                <CardActions>
                                    <Button component={Link} to="/mixed-gases" variant='contained' className='preview-button'>Learn More</Button>
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Card square className="preview-wrap">
                                <CardMedia
                                    className="preview-media"
                                    image={SpecialPreview}
                                />
                                <CardContent>
                                    <Typography variant="h6" className="preview-subtitle">Special App. Mixtures</Typography>
                                    <Typography>High purity gases & specialty gas mixtures play a major role into all types of industrial/manufacturing application from oil & gas refining to semiconductor manufacturing.</Typography>
                                </CardContent>
                                <CardActions>
                                    <Button component={Link} to="/special-application-mixtures" variant='contained' className='preview-button'>Learn More</Button>
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Card square className="preview-wrap">
                                <CardMedia
                                    className="preview-media"
                                    image={EquipmentPreview}
                                />
                                <CardContent>
                                    <Typography variant="h6" className="preview-subtitle">Equipment & Systems</Typography>
                                    <Typography>We provide top-notch gas equipment from gas regulators and flowmeters, to generators, delivery systems and safety products.</Typography>
                                </CardContent>
                                <CardActions>
                                    <Button component={Link} to="/equipment-systems" variant='contained' className='preview-button'>Learn More</Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <ContactForm />
        </div>
    )
};