import { useTheme, useMediaQuery } from '@mui/material';
import NavDesktop from './NavDesktop/NavDesktop';
import NavMobile from './NavMobile/NavMobile';

export default function NavBar() {
    const theme = useTheme();
    const isDesktopScreen = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        isDesktopScreen ? (
            <NavDesktop />
        ) : (
            <NavMobile />
        )
    )
}