import { Typography } from "@mui/material";
import './Hero.scss'

export default function Hero(props) {
    return (
        <div 
            className="hero-root"
            style={{
                backgroundImage: `radial-gradient(circle, rgba(177,179,181,0.25), rgba(113,168,149,.5)), url(${props.url})`,
                backgroundPosition: `${props.position}`
            }}
        >
            <Typography 
                className="hero-title"
                variant="h2" 
            >
                {props.title}
            </Typography>
        </div>
    )
}