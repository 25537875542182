import { Container, Grid, Typography } from '@mui/material';
import Hero from '../../components/Hero/Hero';
import Preview from '../../components/Preview/Preview';
import GasCard from './GasCard/GasCard';
import SpecialApplicationMixesBanner from '../../imgs/banners/special-application-banner.jpg';
import SpecialApplicationMixesTemp from '../../imgs/special-application-temp.JPG';
import MedicalIcon from '../../imgs/svgs/medical-icon.svg';
import FoodBeverageIcon from '../../imgs/svgs/foodbeverage-icon.svg';
import CryogenicIcon from '../../imgs/svgs/cryogenic-icon.svg';
import LabIcon from '../../imgs/svgs/laboratory-icon.svg';
import EPAIcon from '../../imgs/svgs/EPA-logo.svg';
import LaserIcon from '../../imgs/svgs/laser-icon.svg';
// import HydrocarbonIcon from '../../imgs/svgs/hydrocarbon-icon.svg';
import './SpecialApplicationMixtures.scss';

const specialMixesList = [
    { title: "Medical Gases & Mixtures", img: MedicalIcon, desc: [{sec: "USP Oxygen; NF Nitrogen; Medical Air USP; USP CO2; USP Nitrous Oxide"}, {sec: "Pulmonary Function Testing Gas Mixtures"}, {sec: "Anaerobic Gas Mixtures"}, {sec: "Blood Gas Mixtures"}, {sec: "Medical Gas Regulators"}] },
    { title: "Food & Beverage Gases", img: FoodBeverageIcon, desc: [{sec: "Gaseous Nitrogen, CO2, Argon, Oxygen in High Pressure Cylinder"}, {sec: "Liquid Nitrogen, CO2, ARgon, Oxygen in Low & High-pressure Dewar"}, {sec: "Bulk Refrigerated liquid Nitrogen; CO2; Argon & Oxygen"}, {sec: "Liquid Nitrogen & CO2 for Food chilling & Freezing application"}] },
    { title: "Modified Atmosphere Packaging", img: CryogenicIcon, desc: [{sec: "Modified Atmosphere Packging gas mixtures are used for extending shelf-life of food products."}, {sec: "30% CO2/70% Nitrogen mixture"}, {sec: "20% CO2/70% Oxygen Mixture"}, {sec: "0.4% Carbon Monoxide/30% CO2/ 69.6% Nitrogen mixture"}, {sec: "Available in single cylinders; six packs; 16-packs Aluminum; and 18-packs"}] },
    { title: "Portable Calibration Standards", img: LabIcon, desc: [{sec: "Light weight disposable aluminum and steel cylinders in various sizes available"}, {sec: "17 L; 34 L; 103 L; 29 L & 58 L aluminum"}, {sec: "Shelf Life: guaranteed up to 36 months on high quality gas mixtures."}, {sec: "Expiration for reactive gases: Hydrogen Sulfide (H2S) = 24 months"}, {sec: "Sulfur Dioxide (SO2) = 36 months, Phosphine (PH3) = 24 months"}, {sec: "Hydrogen Cyanide (HCN) = 12 months"}, {sec: "Bump Gas Mixture"}] },
    { title: "EPA Protocol Mixtures", img: EPAIcon, desc: [{sec: "EPA Protocol Mixtures are manufactured to stringent EPA specifications and procedures, utilizing the most advanced cylinder prepartion procedures. These mixtures are then analyzed with NIST traceable standards. Aluminum cylinders are recommended for maximum shelf life."}, {sec: "Two Component Mixtures: Nitric Oxide: Nitrogen Dioxide; Carbon Monoxide; Sulfur Dioxide/Carbon Dioxide; Balance Nitrogen or Air"}, {sec: "Four Component Mixtures: CO/CO2/NO/SO2/Balance Nitrogen"}] },
    { title: "Laser Mixtures", img: LaserIcon, desc: [{sec: "Source Material Purities:"}, {sec: "Carbon Dioxide: 99.995%"}, {sec: "Nitrogen: 99.999%"}, {sec: "Helium: 99.999%"}, {sec: "Carbon Monoxide 99.5%"}, {sec: "Hydrogen: 99.999%"}, {sec: "Each cylinder is analyzed for concentration of the minor components, and certified with accuracy of +/-2% of the ordered specification. Further batch tests are conducted for the Oxygen, Moisture and THC impurities and guaranteed within specifications."}] },
];

export default function SpecialApplicationMixtures() {
    return(
        <div className="specialgasmixes-root">
            <Hero 
                url={SpecialApplicationMixesBanner}
                title="Special Application Mixtures"
                position="85% center"
            />
            <Preview 
                title="Special Mixtures"
                desc="High purity gases & specialty gas mixtures play a major role into all types of industrial/manufacturing application from oil & gas refining to semiconductor manufacturing. Gases are required for critical medical research, complex air quality monitoring and food processing to name a few."
                img={SpecialApplicationMixesTemp}
            />
            <div className="specialgasmixes-list">
                <Container>
                    <Typography variant="h3" className="list-title">Our Special Mixtures</Typography>
                    <Grid container justifyContent="center" spacing={2}>
                        {specialMixesList.map((mixes, i) => 
                            <Grid item xs={12} sm={6} md={4} key={i}> 
                                <GasCard 
                                    title={mixes.title}
                                    img={mixes.img}
                                    desc={mixes.desc}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Container>
            </div>
        </div>
    )
};