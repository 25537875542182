import { Container, Grid, Typography } from '@mui/material';
import Hero from '../../components/Hero/Hero';
import Preview from '../../components/Preview/Preview';
import PureGasesBanner from '../../imgs/banners/pure-gas-banner.jpg';
import PureGasesTemp from '../../imgs/puregases-temp.JPG';
import COCIcon from '../../imgs/svgs/COC-icon.svg';
import COAIcon from '../../imgs/svgs/COA-icon.svg';
import './PureGases.scss';

const pureGasesList = [
    { name: "Acetylene" },
    { name: "Air" },
    { name: "Ammonia" },
    { name: "Argon" },
    { name: "Boron Trichloride" },
    { name: "Boron Trifluoride" },
    { name: "1,3-Butadiene" },
    { name: "n-Butane" },
    { name: "1-Butane" },
    { name: "CIS-2-Butene" },
    { name: "Carbon Dioxide" },
    { name: "Carbon Monoxide" },
    { name: "Carbonyl Sulfide" },
    { name: "Chlorine" },
    { name: "Deuterium" },
    { name: "Dimethyl Ether (DME)" },
    { name: "Ethane" },
    { name: "Ethylene" },
    { name: "Helium" },
    { name: "Hexafluoropropylene" },
    { name: "Hydrogen" },
    { name: "Hydrogen Bromide" },
    { name: "Hydrogen Chloride" },
    { name: "Hydrogen Sulfide" },
    { name: "Isobutane" },
    { name: "Krypton" },
    { name: "Methane" },
    { name: "Methyl Chloride" },
    { name: "Methyl Fluoride" },
    { name: "Methyl Mercaptan" },
    { name: "Monomethylamine (MMA)" },
    { name: "Neon" },
    { name: "Neopentane (Dimethylpropane)" },
    { name: "Nitric Oxide" },
    { name: "Nitrogen" },
    { name: "Nitrogen Dioxide" },
    { name: "Nitrogen Trifluoride" },
    { name: "Nitrous Oxide" },
    { name: "Octafluoropropane (HC-218)" },
    { name: "Oxygen" },
    { name: "Propane" },
    { name: "Propylene" },
    { name: "Sulfur Dioxide" },
    { name: "Sulfur Hexafluoride" },
    { name: "Tetrafluoromethane (HC-14)" },
    { name: "Trimethylamine (TMA)" },
    { name: "Xenon" },
]

const chunk = Math.ceil(pureGasesList.length / 4);

export default function PureGases() {
    return(
        <div className="puregases-root">
            <Hero 
                url={PureGasesBanner}
                title="Pure Gases"
                position="5% center"
            /> 
            <Preview 
                title="Gas Purification"
                desc="Barnes specialty gases offers pure gases in high pressure cylinders and liquid Dewar. We provide different grades suitable for various applications such as UHP grade for use as carrier gas in Gas chromatograph, Food grade for processing and packaging food products, bulk CO2 and nitrogen for food freezing application."
                img={PureGasesTemp}
            />
            <div className="puregases-list">
                <Container>
                    <Typography variant="h3" className="list-title">Our Pure Gases</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={3} className="list-wrapper">
                            {pureGasesList.slice(0, chunk).map((gas, i) => 
                                <Typography key={i}>{gas.name}</Typography>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={3} className="list-wrapper">
                            {pureGasesList.slice(chunk, chunk * 2).map((gas, i) => 
                                <Typography key={i}>{gas.name}</Typography>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={3} className="list-wrapper">
                            {pureGasesList.slice(chunk * 2, chunk * 3).map((gas, i) => 
                                <Typography key={i}>{gas.name}</Typography>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={3} className="list-wrapper">
                            {pureGasesList.slice(chunk * 3, chunk * 4).map((gas, i) => 
                                <Typography key={i}>{gas.name}</Typography>
                            )}
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className="puregases-grades">
                <Container>
                    <Typography variant="h3" className="grades-title">Purity Gas Grades</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md>
                            <Typography variant="h5" className="grades-bold">Pre-Purified</Typography>
                            <Typography variant="h4" className="grades-bold">99.99%</Typography>
                            <Typography variant="h6">Min. Purity</Typography>
                        </Grid>
                        <Grid item xs={12} md>
                            <Typography variant="h5" className="grades-bold">Zero</Typography>
                            <Typography variant="h4" className="grades-bold">99.995%</Typography>
                            <Typography variant="h6">Min. Purity</Typography>
                        </Grid>
                        <Grid item xs={12} md>
                            <Typography variant="h5" className="grades-bold">UHP</Typography>
                            <Typography variant="h4" className="grades-bold">99.999%</Typography>
                            <Typography variant="h6">Min. Purity</Typography>
                        </Grid>
                        <Grid item xs={12} md>
                            <Typography variant="h5" className="grades-bold">Ultra Carrier</Typography>
                            <Typography variant="h4" className="grades-bold">99.9995%</Typography>
                            <Typography variant="h6">Min. Purity</Typography>
                        </Grid>
                        <Grid item xs={12} md>
                            <Typography variant="h5" className="grades-bold">Research</Typography>
                            <Typography variant="h4" className="grades-bold">99.9999%</Typography>
                            <Typography variant="h6">Min. Purity</Typography>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className='puregases-certification'>
                <Container>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <img src={COCIcon} alt="CoC icon" className="certification-icon" />
                            <Typography variant="h4" className="certification-title">Certificate of Conformance</Typography>
                            <Typography>Contains information on the Purity of gas expressed in terms of percentage & Maximum concentration of specified impurities in terms of Parts per Million. Purity is classified in different grades and mentioned in numbers such as 4.5, where 4 before the point represents the number of nines and the 5 after point indicates the last decimal, so 4.5 = 99.995%. The actual concentration of impurities is usually lower than stated.</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <img src={COAIcon} alt="CoA icon" className="certification-icon" />
                            <Typography variant="h4" className="certification-title">Certificate of Analysis</Typography>
                            <Typography>A written document that reports the analytical values obtained and certifies the concentration of the components found in a gas mixture or impurities in a pure gas and the traceability to the Reference Standard Material.</Typography>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </div>
    )
};