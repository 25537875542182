import GasWorld from '../../imgs/gas-world.png'

const pressDB = [
  {
    "img": GasWorld,
    "title": "2024 Gas World BSG Article",
    "content": "Barnes Specialty Gases a division of Fresno Oxygen. has been working in gases for food preservation and processing for more than a decade. Director of Specialty Gases Chinmoy Bose walked delegates through the varied and ever-expanding list of applications its gases support at the IWDCs recent Gas Meeting in Santa Rosa. Christian Annesley was there.",
    "author": "Gas World",
    "link": '/pdfs/2024-Gas-World-Article.pdf'
  },
  {
    "img": GasWorld,
    "title": "2024 Gas World Chinmoy Bose Interview",
    "content": "Barnes Specialty Gases, a division of Fresno Oxygen, has been working in gases for food preservation and processing for more than a decade. Director of Specialty Gases Chinmoy Bose spoke to Christian Annesley about the company’s – and his – fantastic journey.",
    "author": "Gas World",
    "link": '/pdfs/2024-Gas-World-Interview.pdf'
  }
]
  
export default pressDB