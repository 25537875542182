import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { HelmetProvider } from "react-helmet-async";
import Home from './views/Home/Home';
import PureGases from './views/PureGases/PureGases';
import MixedGases from './views/MixedGases/MixedGases';
import SpecialApplicationMixtures from './views/SpecialApplicationMixtures/SpecialApplicationMixtures';
import BulkMicrobulk from './views/BulkMicrobulk/BulkMicrobulk';
import EquipmentSystems from './views/EquipmentSystems/EquipmentSystems';
import About from './views/About/About';
import Contact from './views/Contact/Contact';
import NoMatch from './views/NoMatch/NoMatch';
import Press from './views/Press/Press';
import PageHelmet from './components/PageHelmet/PageHelmet';
import NavBar from './components/NavBar/NavBar';
import Footer from './components/Footer/Footer';
import PageTracking from './components/PageTracking/PageTracking';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import ScrollToTopButton from './components/ScrollToTopButton/ScrollToTopButton';
import './App.scss';

export const routes = [
  {
    name: "Home",
    path: "/",
    exact: true,
    component: Home
  },
  {
    name: "Gases",  
    routes: [
      {
        name: "Pure Gases",
        path: "/pure-gases",
        exact: false,
        component: PureGases 
      },
      {
        name: "Mixed Gases",
        path: "/mixed-gases",
        exact: false,
        component: MixedGases
      },
      {
        name: "Special Application Mixtures",
        path: "/special-application-mixtures",
        exact: false,
        component: SpecialApplicationMixtures
      },
    ]
  },
  {
    name: "Services",
    routes: [
      {
        name: "Bulk & Microbulk",
        path: "/bulk-microbulk",
        exact: false,
        component: BulkMicrobulk
      },
      {
        name: "Equipment & Systems",
        path: "/equipment-systems",
        exact: false,
        component: EquipmentSystems
      }
    ]
  },
  {
    name: "Press",
    path: '/press',
    exact: false,
    component: Press
  },
  {
    name: "About",
    path: '/about',
    exact: false,
    component: About
  },
  {
    name: "Contact",
    path: '/contact',
    exact: false,
    component: Contact
  }
];

function App() {
  return (
    <HelmetProvider>
      <div className="app-root">
        <Router>
          <PageTracking />
          <ScrollToTop />
          <ScrollToTopButton />
          <NavBar />
          <Switch>
            {routes.map((route, i) => 
              route.routes ? (
                ( route.routes.map((subroutes, i) => 
                  <Route 
                    key={i} 
                    path={subroutes.path} 
                    exact={subroutes.exact}
                  >
                    <PageHelmet title={subroutes.name} />
                    <subroutes.component />
                  </Route>
                ))
              ) : (
                <Route 
                  key={i} 
                  path={route.path} 
                  exact={route.exact}
                >
                  <PageHelmet title={route.name} />
                  <route.component />
                </Route>
              )
            )}
            <Route path="*">
              <PageHelmet title="Page Not Found" />
              <NoMatch />
            </Route>
          </Switch>
          <Footer />
        </Router>
      </div>
    </HelmetProvider>
  );
}

export default App;