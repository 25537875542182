import { Container, Grid, Typography } from '@mui/material';
import Hero from '../../components/Hero/Hero';
import Preview from '../../components/Preview/Preview';
import AboutBanner from '../../imgs/banners/about-banner.jpg';
import FowlerPlantAerial from '../../imgs/fowler-plant-aerial.jpg';
import Chinmoy from '../../imgs/chinmoy.png';
import Daniel from '../../imgs/daniel.png';
import Fidel from '../../imgs/fidel.png';
import Martin from '../../imgs/martin.png'
import './About.scss';

export default function About() {
    return(
        <div className="about-root">
            <Hero 
                url={AboutBanner}
                title="About Us"
                position="10% center"
            />
            <Preview 
                title="Who We Are"
                desc="Barnes Specialty Gases is a division of Fresno Oxygen & Welding Suppliers, Inc. Barnes Specialty Gases operates from within our Barnes Welding Supply retail locations. We have 12 stores doing business as Barnes Welding Supply. All these stores are strategically located in major industrial hubs along Highway 99 from Bakersfield to Sacramento. We also have 1 location in Salinas to service the central coast. Supporting our stores and our customers we operate four industrial Gas Fill Plants, one Specialty Gas Lab and a Central Distribution Warehouse."
                img={FowlerPlantAerial}
                bgColor="#71a895"
                flip
            />
            <div className="about-team">
                <Container>
                    <Typography variant="h3" className="team-title">Our Team</Typography>
                    <Grid container spacing={2} justifyContent="center" className="team-list">
                        <Grid item xs={12} sm={6} md={3}>
                            <div>
                                <img src={Chinmoy} alt="chinmoy" className="team-img" />
                            </div>
                            <Typography variant="h4">Chinmoy Bose</Typography>
                            <Typography variant="h5">Specialty Gas Director</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <div>
                                <img src={Daniel} alt="daniel" className="team-img" />
                            </div>
                            <Typography variant="h4">Daniel Serrato</Typography>
                            <Typography variant="h5">Gas Lab Supervisor</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <div>
                                <img src={Fidel} alt="fidel" className="team-img" />
                            </div>
                            <Typography variant="h4">Fidel Torrez</Typography>
                            <Typography variant="h5">Gas Lab Technician</Typography>
                        </Grid>                        
                        <Grid item xs={12} sm={6} md={3}>
                            <div>
                                <img src={Martin} alt="Martin" className="team-img" />
                            </div>
                            <Typography variant="h4">Martin Gomez</Typography>
                            <Typography variant="h5">Gas Lab Technician</Typography>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </div>
    )
};