import { Container, Grid, Typography } from '@mui/material';
import Hero from '../../components/Hero/Hero';
import Preview from '../../components/Preview/Preview';
import EquipmentCard from './EquipmentCard/EquipmentCard';
import GasEquipmentBanner from '../../imgs/banners/equipment-servicing-banner.jpg';
import GasEquipmentTemp from '../../imgs/equipment-temp.JPG';
import Series302 from '../../imgs/equipment/302-Series-Brass-Regulators.jpg';
import Series308 from '../../imgs/equipment/308-Series-Heated-CO2-Regulators.jpg';
import Series312 from '../../imgs/equipment/312-Series-Brass-Regulators.jpg';
import Series422 from '../../imgs/equipment/422-Series-Stainless-Regulators.jpg';
import Series432 from '../../imgs/equipment/432-Series-Stainless-Regulators.jpg';
import Series455 from '../../imgs/equipment/455-Series-Regulators.jpg';
import Series486 from '../../imgs/equipment/486-Series-High-Flow-Regulators.jpg';
import Series526 from '../../imgs/equipment/526-Semi-automatic-Gas-switchover.jpg';
import Series539 from '../../imgs/equipment/539-Series-Electronic-switchover-IntelliSwitch.jpg';
import Series53 from '../../imgs/equipment/53-Point-of-Use-Panels.jpg';
import Series6300 from '../../imgs/equipment/6300-series-Oxygen-Trap.jpg';
import Series8200 from '../../imgs/equipment/8200-series-Hydrocarbon-Trap.jpg';
import './EquipmentSystems.scss';

const equipmentList = [
    { 
        img: Series302,
        title: "302 Series Brass Regulators",
        desc: "Chrome Plated Brass Single Stage, Stainless Diaphragm 4 Port Cylinder Regulators for non-corrosive application. Output range: 0-15; 0-50; 0-100; 0-250; 0-500 psi"
    },
    {
        img: Series308,
        title: "308 Series Heated CO2 Regulators",
        desc: "Brass Single Stage, Stainless Diaphragm with Heater specifically designed to prevent freeze-up problems with CO2 and nitrous Oxide. Output range: 0-15; 0-30; 0-50; 0-100 psi"
    },
    {
        img: Series312,
        title: "312 Series Brass Regulators",
        desc: "Chrome Plated Brass Single Stage, Stainless Diaphragm 4 Port Cylinder Regulators for non-corrosive application. Output range: 0-15; 0-50; 0-100; 0-250; 0-500"
    },
    {
        img: Series422,
        title: "422 Series Stainless Regulators",
        desc: "316L Stainless Steel body, Single Stage, Stainless Diaphragm 6 Port Cylinder Regulators Corrosion resistant. Metal to Metal diaphragm seal that can be used for Ammonia gas. Output range: 0-15; 0-50; 0-100; 0-250; 0-500 "
    },
    {
        img: Series432,
        title: "432 Series Stainless Regulators",
        desc: "316L Stainless Steel body, Dual Stage, Stainless Diaphragm 6 Port Cylinder Regulators Corrosion resistant. Metal to Metal diaphragm seal that can be used for Ammonia gas. Output range: 0-15; 0-50; 0-100; 0-250; 0-500"
    },
    {
        img: Series455,
        title: "455 Series Regulators",
        desc: "Nickle plated brass body, Stainless Diaphragm with Deep Purge Assembly for corrosive gases such as Chlorides, Sulfides. Output range: 0-15; 0-40; 0-120; 0-200; 0-250"
    },
    {
        img: Series486,
        title: "486 Series High Flow Regulators",
        desc: "316L Stainless Steel Barstock, Single Stage, Half inch output Port High Flow, High Purity Applications. Output range: 0-15; 0-40; 0-120; 0-200; 0-250"
    },
    {
        img: Series526,
        title: "526 Semi-Automatic Gas Switchover",
        desc: "Fully automatic computer-controlled gas switcher for high pressure cylinders and cryogenic liquid Dewar. 110 or 220 VAC power supply. Half inch outlet port for high flow."
    },
    {
        img: Series539,
        title: "539 Series Electronic Switchover IntelliSwitch",
        desc: "Semi-automatic gas switcher for high pressure cylinders. It automatically switches to the reserve side when the primary side is low."
    },
    {
        img: Series53,
        title: "53 Point of Use Panels",
        desc: "Modular design provides fine control of pressure at point of use for laboratory instruments or process control equipment."
    },
    {
        img: Series6300,
        title: "6300 Series Oxygen Trap",
        desc: "Installed on high purity carrier gas lines for gas chromatographs in laboratories. The traps are capable of reducing oxygen content in pure gases to a purity of 99.9999% purity level."
    },
    {
        img: Series8200,    
        title: "8200 Series Hydrocarbon Trap",
        desc: "Installed on high purity carrier gas lines for gas chromatographs in laboratories. The traps are capable of removing organics such as alcohols, aromatics, chlorinated hydrocarbons."
    }
]

export default function EquipmentServicing() {
    return(
        <div className="equipmentsystems-root">
            <Hero 
                url={GasEquipmentBanner}
                title="Equipment & Gas Delivery Systems"
                position="85% center"
            /> 
            <Preview
                title="Equipment"
                desc="Here at Barnes Welding Supply, we don't merely sell high purity gas. We provide top-notch gas equipment from gas regulators and flowmeters, to generators, delivery systems and safety products, in order to guarantee that customers get the most out of their PurityPlus gases while never compromising safety, capability, or efficiency."
                img={GasEquipmentTemp}
            />
            <div className="equipmentsystems-list">
                <Container>
                    <Typography variant="h3" className="list-title">Our Equipment</Typography>
                    <Grid container spacing={2} justifyContent="center">
                        {equipmentList.map((equipment, i ) => 
                            <Grid item xs={12} sm={6} md={4} key={i}>
                                <EquipmentCard 
                                    title={equipment.title}
                                    img={equipment.img}
                                    description={equipment.desc}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Container>
            </div>
        </div>    
    )
};