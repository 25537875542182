import * as React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Drawer, List, ListItem, ListItemText, Collapse, IconButton, Typography } from "@mui/material";
import { routes } from '../../../App';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import './NavMobile.scss';

export default function NavMobile() {
    const [drawerState, setDrawerState] = React.useState(false);
    const [dropdownIndex, setDropdownIndex] = React.useState("");

    const handleDrawerToggle = () => {
        setDrawerState(!drawerState);
    }

    const handleDropdownToggle = (index) => {
        if ( dropdownIndex === index ) {
            setDropdownIndex("");
        } else {
            setDropdownIndex(index);
        }
    }

    return (
        <AppBar position="static" className="navmobile-root">
            <Toolbar className="nav-toolbar" id="back-to-top-anchor">
                <div className="nav-buttonwrap">
                    <IconButton
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2 }}
                        className="nav-button"
                    >
                        <MenuIcon />
                    </IconButton>
                </div>
                <Drawer
                    anchor={"left"}
                    open={drawerState}
                    onClose={handleDrawerToggle}
                >
                    <List>
                        {routes.map((route, i) => 
                            route.routes ? (
                                <div key={i}>
                                    <ListItem
                                        button
                                        onClick={() => handleDropdownToggle(i)}
                                    >
                                        <ListItemText primary={route.name}/>
                                        { dropdownIndex === i ? <ExpandLessIcon /> : <ExpandMoreIcon /> } 
                                    </ListItem>
                                    <Collapse
                                        in={dropdownIndex === i}
                                    >
                                        <List>
                                            {route.routes.map((subroutes, i) => (
                                                <ListItem 
                                                    key={i}
                                                    button
                                                    component={Link}
                                                    to={subroutes.path}
                                                    onClick={handleDrawerToggle}
                                                >
                                                    <ListItemText primary={subroutes.name} />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Collapse>
                                </div>
                            ) : (
                                <ListItem 
                                    key={i} 
                                    button 
                                    component={Link}
                                    to={route.path}
                                    onClick={handleDrawerToggle}
                                >
                                    <ListItemText primary={route.name}/>
                                </ListItem>
                            )
                        )}
                    </List>
                </Drawer>
                <Typography variant="h6" className="nav-title">
                    Barnes Specialty Gases
                </Typography>
            </Toolbar>
        </AppBar>
    )
}