import * as React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Button, Container, TextField, Typography } from '@mui/material';
import './ContactForm.scss';

export default function ContactForm() {
    const [state, setState] = React.useState({
        fullName: '',
        email: '',
        message: '',
    });
    const [token, setToken] = React.useState('');
    const [errors, setErrors] = React.useState({});

    const validate = () => {
        let temp = {...errors};
        if ('fullName' in state)
            temp.fullName = state.fullName ? '' : 'Please enter your name.';
        if ('email' in state) 
            temp.email = state.email && /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/.test(state.email) ? '' : 'Please enter a valid email address.';
        if ('message' in state)
            temp.message = state.message ? '' : 'Please enter a message.';

        setErrors({
            ...temp,
        })

        if (state)
            return Object.values(temp).every((x) => x === '');
    }

    function handleChange(event) {
        const value = event.target.value;
        setState({
            ...state,
            [event.target.name]: value
        });
    };

    function captchaChange(value) {
        setToken(value);
    }

    function sendEmail(e) {
        e.preventDefault();
        if (validate())
        {
            if (token) {
                const requestOptions = {
                    method: 'POST',
                    body: JSON.stringify({"body":{"fullName":state.fullName,"email":state.email,"message":state.message,"source":"BSG","token":token}}),
                };
                
                fetch(`https://ac6xrieiik.execute-api.us-east-2.amazonaws.com/default/bws-contact-form`, requestOptions)
                .catch(err => console.log(err));
                
                setState({...state, fullName: '', email: '', message: ''});
            }
        }
    };

    return (
        <div className="contact-form-root">
            <Container>
                <Typography variant="h3">Have a question for our team?</Typography>
                <form 
                    onSubmit={sendEmail}
                    noValidate
                    className="contact-form-wrapper"
                >
                    <TextField 
                        label="Name"
                        name="fullName"
                        value={state.fullName}
                        onChange={handleChange}
                        error={!!errors.fullName}
                        helperText={errors.fullName}
                        variant="outlined"
                        fullWidth
                        required
                        className="contact-textfield"
                    />
                    <TextField 
                        label="Email"
                        name="email"
                        type="email"
                        value={state.email}
                        onChange={handleChange}
                        error={!!errors.email}
                        helperText={errors.email}
                        variant="outlined"
                        fullWidth
                        required
                        className="contact-textfield"
                    />
                    <TextField 
                        label="Message"
                        name="message"
                        value={state.message}
                        onChange={handleChange}
                        error={!!errors.message}
                        helperText={errors.message}
                        variant="outlined"
                        fullWidth
                        multiline
                        minRows={6}
                        required
                        className="contact-textfield"
                    />
                    <ReCAPTCHA 
                        sitekey='6LcMs7gdAAAAAMcm4rAdZG5rBVb8CiqOLE3qqEXE'
                        onChange={captchaChange}
                        className="contact-captcha"
                    />
                    <Button
                        type="submit"
                        variant="outlined"
                        className="contact-button"
                    >
                        Submit
                    </Button>
                </form>
            </Container>
        </div>
    )
}   