import * as React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, Menu, MenuItem } from '@mui/material';
import { routes } from '../../../App';
import './NavDesktop.scss';

export default function NavDesktop() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleMenuClick = (index, event) => {
        setAnchorEl({ [index]: event.currentTarget });
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <AppBar position="static" className="navdesktop-root">
            <Toolbar className="nav-toolbar" id="back-to-top-anchor">
                <Typography variant="h6" className="nav-title">
                    Barnes Specialty Gases
                </Typography>
                {routes.map((route, i) => 
                    route.routes ? (
                        <div key={i}>
                            <Button onClick={(e) => handleMenuClick(i, e)} className="nav-link">{route.name}</Button>
                            <Menu 
                                anchorEl={anchorEl && anchorEl[i]} 
                                open={Boolean(anchorEl && anchorEl[i])}
                                onClose={handleMenuClose}
                                className="nav-menu"
                            >
                                {route.routes.map((subroutes, i) => (
                                    <MenuItem 
                                        key={i} 
                                        component={Link} 
                                        to={subroutes.path}
                                    >
                                        {subroutes.name}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </div>
                    ) : (
                        <Button 
                            key={i} 
                            component={Link} 
                            to={route.path} 
                            className="nav-link"
                        >
                            {route.name}
                        </Button>
                    )
                )}
            </Toolbar>
        </AppBar>
    )
};