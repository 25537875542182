import { Link } from 'react-router-dom';
import { Container, Grid } from '@mui/material'
import pressDB from './db'
import PressBanner from '../../imgs/banners/press-banner.JPG';
import './Press.scss'
import Hero from '../../components/Hero/Hero';
 
export default function Press() {
  return (
    <div className='press-root'>
      <Hero             
        url={PressBanner}    
        title="Press"
      />
      <Container>
        <div className='press-list'>
          {pressDB.map((press, i) => (
            <div className='press-preview' key={i}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={3} className='press-img-wrapper'>
                  <img src={press.img} alt={press.title} className='press-img' />
                </Grid>
                <Grid item xs={12} md={9}>
                  <Link to={process.env.PUBLIC_URL + press.link} target='_blank'>
                    <h2>{press.title}</h2>
                    <p>{press.content}</p>
                    <p className='press-author'>Written by {press.author}</p>
                  </Link>
                </Grid>
              </Grid>
            </div>
          ))}
        </div>
      </Container>
    </div>
  )
}