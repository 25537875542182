import { HashLink } from 'react-router-hash-link';
import { Container, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import { LocationOn, Phone, Email } from '@mui/icons-material';
import './Footer.scss';

export default function Footer() {
    return (
        <div className="footer-root">
            <Container>
                <Grid container>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h5" className="footer-title">Contact Us</Typography>
                        <List>
                            <ListItem>
                                <ListItemIcon className="contact-icon">
                                    <LocationOn />
                                </ListItemIcon>
                                <ListItemText 
                                    primary="Address"
                                    secondary="2825 S. Elm Ave #101 Fresno, CA 93706"
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon className="contact-icon">
                                    <Phone />
                                </ListItemIcon>
                                <ListItemText 
                                    primary="Phone"
                                    secondary="1 (888) 394-2737"
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon className="contact-icon">
                                    <Email />
                                </ListItemIcon>
                                <ListItemText 
                                    primary="Email"
                                    secondary="chinmoyb@barnesspecialtygases.com"
                                />
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h5" className="footer-title">Useful Links</Typography>
                        <List>
                            <ListItem>
                                <a
                                    href="https://barnesweldingsupply.com/"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="footer-link"
                                >
                                    <ListItemText 
                                        primary="Barnes Welding Supply"
                                    />
                                </a>
                            </ListItem>
                            <ListItem>
                                <a
                                    href="https://www.purityplusgases.com/"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="footer-link"
                                >
                                    <ListItemText 
                                        primary="Purity Plus"
                                    />
                                </a>
                            </ListItem>
                            <ListItem>
                                <a
                                    href="https://www.purityplusgases.com/purityplus-special-gas-catalog/"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="footer-link"
                                >
                                    <ListItemText 
                                        primary="Purity Plus Catalog"
                                    />
                                </a>
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h5" className="footer-title">12 Locations to Serve You!</Typography>
                        <div className='location-list'>
                            <Stack
                                direction="row" 
                                spacing={2}
                                divider={<Typography color={'#fff200'}>●</Typography>}   
                                className='list-row'
                            >
                                <Typography component={HashLink} to="/contact?location=1#search" className="footer-link">Fresno</Typography>
                                <Typography component={HashLink} to="/contact?location=7#search" className="footer-link">Bakersfield</Typography>
                                <Typography component={HashLink} to="/contact?location=4#search" className="footer-link">Clovis</Typography>
                            </Stack>
                            <Stack
                                direction="row" 
                                spacing={2}
                                divider={<Typography color={'#fff200'}>●</Typography>}   
                                className='list-row'
                            >
                                <Typography component={HashLink} to="/contact?location=6#search" className="footer-link">Citrus Heights</Typography>
                                <Typography component={HashLink} to="/contact?location=5#search" className="footer-link">Madera</Typography>
                                <Typography component={HashLink} to="/contact?location=9#search" className="footer-link">Modesto</Typography>
                            </Stack>
                            <Stack
                                direction="row" 
                                spacing={2}
                                divider={<Typography color={'#fff200'}>●</Typography>}   
                                className='list-row'
                            >
                                <Typography component={HashLink} to="/contact?location=3#search" className="footer-link">Porterville</Typography>
                                <Typography component={HashLink} to="/contact?location=8#search" className="footer-link">Sacramento</Typography>
                                <Typography component={HashLink} to="/contact?location=12#search" className="footer-link">Salinas</Typography>
                            </Stack>
                            <Stack
                                direction="row" 
                                spacing={2}
                                divider={<Typography color={'#fff200'}>●</Typography>}   
                                className='list-row'
                            >
                                <Typography component={HashLink} to="/contact?location=10#search" className="footer-link">Stockton</Typography>
                                <Typography component={HashLink} to="/contact?location=11#search" className="footer-link">Turlock</Typography>
                                <Typography component={HashLink} to="/contact?location=2#search" className="footer-link">Visalia</Typography>
                            </Stack>
                        </div>
                        <Divider className="footer-divider" />
                        <Typography className="footer-title">&copy; {new Date().getFullYear()} Fresno Oxygen. All Rights Reserved.</Typography>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}