import * as React from 'react';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import './EquipmentCard.scss';

export default function EquipmentCard(props) {
    return (
        <Card className="equipmentcard-root">
            <CardMedia 
                component="img"
                height="200"
                image={props.img}
                alt={props.title}
            />
            <CardContent>
                <Typography variant="h6">{props.title}</Typography>
                <Typography>{props.description}</Typography>
            </CardContent>
        </Card>
    )
}