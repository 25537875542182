import { Container, Grid, Typography } from '@mui/material';
import Hero from '../../components/Hero/Hero';
import Preview from '../../components/Preview/Preview';
import MixedGasesBanner from '../../imgs/banners/mixed-gas-banner.jpg';
import MixedGasesTemp from '../../imgs/mixedgases-temp.jpg';
import './MixedGases.scss';

const mixedGasesList = [
    { name: "Ammonia" },
    { name: "Argon" },
    { name: "n-Butane" },
    { name: "Carbon Dioxide" },
    { name: "Carbon Monoxide" },
    { name: "Ethane" },
    { name: "Ethylene" },
    { name: "Helium" },
    { name: "Hexane" },
    { name: "Hydrogen" },
    { name: "Hydrogen Sulfide" },
    { name: "Isobutane" },
    { name: "Methane" },
    { name: "Nitric Oxide" },
    { name: "Nitrogen" },
    { name: "Nitrogen Dioxide" },
    { name: "Nitrous Oxide" },
    { name: "Oxygen" },
    { name: "n-Pentane" },
    { name: "Propane" },
    { name: "Propylene" },
    { name: "Sulfur Dioxide" },
    { name: "Sulfur Hexafluoride" },
    { name: "Three Component Mixtures" },
]

const chunk = Math.ceil(mixedGasesList.length / 3);

export default function MixedGases() {
    return(
        <div className="mixedgases-root">
            <Hero 
                url={MixedGasesBanner}
                title="Mixed Gases"
                position="30% center"
            /> 
            <Preview 
                title="Gas Mixture"
                desc="Mixtures are manufactured using high precision gravimetric scales combined with the state of the art blending system. Automated Pre & Post fill cylinder treatment ensures greater stability of the gas mixture."
                img={MixedGasesTemp}
                bgColor="#71a895"
                flip
            />
            <div className="mixedgases-mixeslist">
                <Container>
                    <Typography variant="h3" className="mixeslist-title">Our Mixes</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4} className="mixeslist-wrapper">
                            {mixedGasesList.slice(0, chunk).map((gas, i) => 
                                <Typography key={i}>{gas.name}</Typography>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={4} className="mixeslist-wrapper">
                            {mixedGasesList.slice(chunk, chunk * 2).map((gas, i) => 
                                <Typography key={i}>{gas.name}</Typography>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={4} className="mixeslist-wrapper">
                            {mixedGasesList.slice(chunk * 2, chunk * 3).map((gas, i) => 
                                <Typography key={i}>{gas.name}</Typography>
                            )}
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className="mixedgases-grades">
                <Container>
                    <Typography variant="h3" className="grades-title">Mixed Gas Grades</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h4">Primary Standard Grade</Typography>
                            <Typography>Precisely blended mixtures prepared by the most advanced gravimetric techniques, using NIST traceable weights for calibrating the high precision scale. Minor components are traceable to NIST Standard Reference Material. The gas mixture is Dual certified by Lab analysis report.</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h4">Certified Standard Grade</Typography>
                            <Typography>A specialty gas mixture (calibration gas) used as a comparative standard for analytical instrumentation is referred to as a Certified Standard. Certificate specifies the gas concentration, traceability to primary standard, blend tolerance & analytical certification accuracy.</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h4">Custom Mixtures Grade</Typography>
                            <Typography>Process gas standards that are prepared in the same fashion, and to the same specifications as the Certified Standards, but the analysis is not reported. The requested composition is reported on the label.</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h4">Environmental Mixtures Grade</Typography>
                            <Typography>Gas mixtures used for the calibration of emission monitoring equipment. These mixtures are manufactured to stringent EPA specifications and certified using NIST traceable SRM.</Typography>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </div>
    )
};