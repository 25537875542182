import * as React from 'react';
import { Card, CardActions, CardContent, Collapse, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './GasCard.scss';

export default function GasCard(props) {
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = (e) => {
        setExpanded(!expanded);
        e.currentTarget.querySelector('.MuiSvgIcon-root').classList.toggle("rotate")
    };

    return(
        <Card 
            className="gascard-root"
            expand={{expanded}}
            onClick={handleExpandClick}
        >
            <CardContent className="gascard-head">
                <img src={props.img} alt={props.title} className="head-img" />
                <Typography variant="h5" className="head-title">{props.title}</Typography>
            </CardContent>
            <CardActions>
                <div className="button-center">
                    <ExpandMoreIcon />
                </div>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent className="gascard-body">
                    {props.desc.map((apps, i) => 
                        <Typography key={i}>{apps.sec}</Typography>
                    )}
                </CardContent>
            </Collapse>
        </Card>
    )
}