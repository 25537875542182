import { Container, Grid, Typography } from '@mui/material';
import Hero from '../../components/Hero/Hero';
import Preview from '../../components/Preview/Preview';
import BulkMicrobulkBanner from '../../imgs/banners/bulk-microbulk-banner.jpg';
import CylinderRefillsTemp from '../../imgs/bulkmicrobulk-temp.JPG';
import TelemetryIcon from '../../imgs/svgs/telemetry-icon.svg';
import './BulkMicrobulk.scss';

export default function BulkMicrobulk() {
    return(
        <div className="bulkmicrobulk-root">
            <Hero 
                url={BulkMicrobulkBanner}
                title="Bulk & Microbulk"
                position="85% center"
            />
            <Preview
                title="Liquid Storage Tank"
                desc="For your requirement of large volume of liquid Nitrogen, CO2, Argon, and Oxygen, we can offer our turnkey solution of installing bulk and microbulk storage tanks, and fill them on-site by our bulk liquid trailers. We size our bulk supply systems appropriately considering the pressure and flow requirements of the customer's process."
                img={CylinderRefillsTemp}
                bgColor="#71a895"
                flip
            />
            <div className="bulkmicrobulk-telemetry">
                <Container>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <img src={TelemetryIcon} alt="Demand Delivery" className="telemetry-svg" />
                            <Typography variant="h3" className='telemetry-title'>Telemetry System</Typography>
                            <Typography>We install our telemetry system on our tanks for 24-hour monitoring of the liquid levels and prevent any product run-out issues. The system sends email alerts to our dispatch and plant professionals when the level reaches the reorder set point. Our bulk delivery trucks then delivery the product on time, offering total satisfaction to our customers.</Typography>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </div>
    )
};